<template>
    <div class="home">
        <div class="video-dialog" v-if="showVideo">
            <div>
                <div class="close_icon" @click="showVideo=false">x</div>
                <video controls="controls" ref="videoBox">
                    <source :src="video" />
                </video>
            </div>
        </div>


        <div class="swiper-container top_box" id="home1Right_swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,i) in banner" :key="i">
                    <div class="bg_img" :style="'background-image: url('+item.url+')'" v-if="item.type=='img'"></div>
                    <video class="video_box" muted  autoplay="autoplay" loop :ref="'topBanner_video_'+i" v-else>
                        <source :src="item.url" />
                    </video>
                </div>
            </div>
            <div class="home1Right-pagination"></div>
            <div class="content_ src-bottom">
                <div>{{bannerInfo.title||'累计服务商户超'}}<span class="big"><span ref="number_1">0</span>万家</span></div>
                <div>{{bannerInfo.url||'年交易规模突破'}}<span class="big" ><span ref="number_2">0</span>亿</span></div>
                <div>{{bannerInfo.description||'创投基金投资企业超'}}<span class="big"><span ref="number_3">0</span>家</span></div>
                <div>{{bannerInfo.content||'易宝产发——行业领先的数字经济产业运营商'}}</div>
            </div>
        </div>

        <!-- 智云应用 -->
        <div class="product_center">
            <div class="content">
                <div class="left_">
                    <img src="../assets/img/home_2_icon.png" class="src-bottom">
                    <button :class="{'hidden':!video}" @click="playViedo">
                        <span>播放宣传片</span>
                        <img src="../assets/img/jt_a.png" alt="">
                    </button>
                    <ul class="scrollbar_box">
                        <li v-for="(item,index) in home1_left" :key="item.id" :class="{'active':index==selectInfo}" @mouseenter="selectInfo=index" 
                        @click="onInfoDetail(item.id, index)">
                            <div class="name">{{item.title}}</div>
                            <div class="info">
                                <span class="floatLf">{{item.time|set_time}}</span>
                                <span class="floatRt">查看详情></span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="right_ src-right">
                    <div class="img_box">
                        <template v-for="(item,index) in home1_right" >
                            <div class="swiper-container" :key="item.id" v-show="index == selectArea" :id="'home2Right_swiper_'+index">
                                <div class="swiper-wrapper ">
                                    <div class="swiper-slide swiper-no-swiping" v-for="(img,i) in item.img" :key="i">
                                        <div class="bg_img" :style="{backgroundImage:'url('+img+')'}"></div>
                                    </div>
                                </div>
                                <div :class="'home2Right-pagination home2Right-pagination_'+index"></div>
                            </div>
                        </template>
                    </div>
                    <!-- <div class="swiper-container " id="home2Right_swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="(item,i) in selectAreaItem" :key="i">
                                <div class="bg_img" :style="{backgroundImage:'url('+item+')'}"></div>
                            </div>
                        </div>
                        <div class="home2Right-pagination"></div>
                    </div> -->
                    <div class="btn-list" >
                        <template v-for="(item,index) in home1_right" >
                            <button :style="{width:100/home1_right.length+'%'}" :class="{'active':index==selectArea}" :key="item.id"
                             @click="onSelectArea(index)">{{item.title}}</button>
                        </template>
                    </div>
                </div>
            </div>
            <div class="home_box_title">
                <span>{{home3_right_name||'智云应用'}}</span>
                <div class="english absol_">PRODUCT CENTRE</div>
            </div>
        </div>

        <div class="home_3_box">
            <div class="big_bg"></div>
            <div class="content">
                <div class="left_">
                    <ul >
                        <li v-for="(item,pIndex) in home3_right" :key="item.name">
                            <div class="title">
                                <img :src="item.thumbnail" >
                                <span>{{item.name}}</span>
                            </div>
                            <div class="info" v-for="(tab,i) in item.child" :key="tab.id" 
                                @mouseenter="mouseenterHome3(pIndex,i)" @mouseleave="mouseleaveHome3()" 
                            :class="{'active':seleHome3 == pIndex+'_'+i}">
                                <img src="../assets/img/jt_w.png" v-show="seleHome3 == pIndex+'_'+i">
                                <span>{{tab.title}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="middle_"></div>
                <div class="right_ src-right">
                    <div class="img_box" :style="{backgroundImage:'url('+selectHome3Img+')'}"></div>
                </div>
            </div>
        </div>

        <!-- 专家智库 -->
        <expert-think-tank class="home_4_box src-bottom-1" :info="home4"></expert-think-tank>

        <!-- 行业解决方案 -->
        <div class="home_5_box">
            <div class="left_">
                <div class="floatRt">
                    <img :src="home5.img" class="src-bottom">
                    <div class="home_box_title">
                        <span>{{home5.name||'行业解决方案'}}</span>
                        <div class="english">INDUSTRY<br/>SOLUTIONS</div>
                    </div>
                </div>
            </div>
            <div class="right_">
                <div class="swiper-container box-tab" id="home5Right_swiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide swiper-no-swiping" v-for="(item,i) in home5.list" :key="i">
                            <div class="tab_" v-for="tab in item" :key="tab.id" @click="goDetail(tab.id)">
                                <div class="name">{{tab.title}}</div>
                                <div class="info">{{tab.excerpt}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="next_prev_box" v-if="this.home5.arr>1">
                    <div class="home5Right_prev"></div>
                    <div class="home5Right_next"></div>
                </div>
            </div>
        </div>

        <!-- 城市合作案例 -->
        <div class="home_6_box">
            <div class="content_">
                <div class="home_box_title">
                    <span>{{home6.name||'城市合作案例'}}</span>
                    <div class="english absol_">COOPERATION CASES</div>
                </div>

                <div class="city-list">
                    <div v-for="(item, i) in home6.list" :key="item.id" :class="{'active': home6_select==i}" @click="home6_select=i">
                        <!-- <span>{{item.title}}</span> -->
                        <div class="bg_" :style="{backgroundImage:'url('+item.img_s+')'}"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 助力城市产业升级 -->
        <div class="home_7_box" ref="home7_box">
            <div class="name">{{home6_Item.keywords}}</div>
            <div class="info" v-html="home6_Item.content||''"></div>
        </div>
        <div class="home-footer">
            <img class="info_img" src="../assets/img/footer_2.png" alt="">

            <footer-box></footer-box>
        </div>
    </div>
</template>
<script>
import footerBox from '@/components/footer-box'
import expertThinkTank from "./components/expert-think-tank";
import {timedCountFunc} from "@/util/index"
let setIntervalFun1 = null,
    setIntervalFun2=null;
export default {
    components:{footerBox, expertThinkTank},
    name:'home',
    data(){
        return {
            video:null,
            selectInfo: 0,
            selectArea:0,
            seleHome3:'0_0',

            showVideo:false,
            banner:[],
            bannerInfo:{},
            bannerIndex:0,
            prefixImg:'',
            home1_left:[],
            home1_right:[],
            home3_right:[],
            home3_right_name:'',
            home4: {},
            home5:{
                img:'',
                name:'',
                list:{},
                arr: 0,
            },
            home6:{
                name:'',
                list:[],
            },
            home6_select:null,
            home7:{},
            home2Right_swiper:null,
            home6_Item:{},
        }
    },
    computed:{
        selectHome3Img(){
            let i = this.seleHome3.split('_'),
                pIndex = i[0]||0, index = i[1]||0;
            return this.home3_right[pIndex]&&this.home3_right[pIndex].child[index]&&this.home3_right[pIndex].child[index].thumbnail;
        },
    },
    watch:{
      home6_select(val){
            this.home6_Item = this.home6.list[val]||{}
            let el = this.$refs.home7_box;
            el.classList.add('active')
            setTimeout(() => {
                el.classList.remove('active')
            }, 500);
      }  
    },
    created(){
        this.initData()
    },
    beforeDestroy(){
        clearInterval(setIntervalFun1)
        clearInterval(setIntervalFun2)
    },
    mounted(){
        
    },
    methods:{
        onInfoDetail(id, index){
            this.selectInfo = index;
            this.goDetail(id);
        },
        goDetail(id){
            this.$router.push({name:'infomationDetail', query:{id: id}})
        },
        playViedo(){
            if(!this.video)return false;
            this.showVideo = true;
            this.$nextTick(() => {
                let el = this.$refs.videoBox;
                el.play();
            })
        },
        initData(){
            this.$Request({
                url: '/portal/home',
                method: 'get',
            }).then(({data})=>{
                data = data||{};
                this.video = data.video;
                this.prefixImg = data.url+'/';

                //bnner
                this.$set(this, 'bannerInfo', data.content||{})
                timedCountFunc(this.$refs['number_1'], 0, data.server_num||0)
                timedCountFunc(this.$refs['number_2'], 0, data.year_trading||0)
                timedCountFunc(this.$refs['number_3'], 0, data.fund_investment||0)

                let bannerLis = [];
                this.bannerIndex = 0;
                this.banner = this.$getListByObj(data.index_banner).map(item=>{
                    let t = {}
                    if(item.image){
                        bannerLis.push(false)
                        t = {
                            url: this.prefixImg+''+item.image,
                            type: 'img'
                        }
                    }else{
                        bannerLis.push(true)
                        t = {
                            url: this.prefixImg+''+item.video,
                            type: 'video'
                        }
                    }
                    return t
                });
                this.$nextTick(()=>{
                    if(bannerLis[0]){
                        let el = this.$refs[`topBanner_video_0`][0]
                        el&&el.play()
                    }
                    if(this.banner.length>1){
                        const that = this;
                        new Swiper ('#home1Right_swiper', {
                            pagination: '.home1Right-pagination',
                            paginationClickable :true,
                            onSlideChangeEnd: function(swiper){
                                let it = swiper.activeIndex,
                                    beforeI = that.bannerIndex;
                                    // this.bannerIndex
                                if(bannerLis[beforeI]){
                                    that.$refs[`topBanner_video_${beforeI}`][0].pause()
                                }
                                if(bannerLis[it]){
                                    that.$refs[`topBanner_video_${it}`][0].play()
                                }
                                that.bannerIndex = it;
                            }
                        })
                    
                    }
                })
                this.home1_left = data.digital_economy_empowerst;
                let home1_right = this.$initNes(data.digital_economy_industrial_park).val.splice(0,4);
                this.home1_right = home1_right.map(item=>{
                    item.img = (item.img || []).map(item=>{
                        return this.prefixImg+''+item.url
                    })
                    return item    
                })
                if(this.home1_right.length>0){
                    this.selectArea = 0;
                    this.initIntervalFun1()
                }
                //智云应用
                let application = data.application||{};
                this.home3_right_name = application.name;
                let home3 = ['government_application', 'smart_cloud_management', 'enterprise_service', 'five_star'];
                home3.forEach(item=>{
                    let obj = this.$initNes(application[item],'more_thumbnail');
                    if(obj.val.length>0){
                        this.home3_right.push({
                            name: obj.name,
                            thumbnail: obj.more_thumbnail,
                            child:obj.val
                        })
                    }
                })
                if(this.home3_right.length>0){
                    this.seleHome3 = '0_0'
                    this.initIntervalFun2()
                }
                //专家智库
                let experts_think_tank = this.$initNes(data.experts_think_tank||{})
                this.$set(this, 'home4', {
                    name: experts_think_tank.name,
                    desc: data.desc,
                    list1: experts_think_tank.val.splice(0,8),
                    list2: experts_think_tank.val.splice(0,4),
                })
                //行业解决方案
                let industry_solutions = this.$initNes(data.industry_solutions||{},'more_thumbnail')
                this.home5.name = industry_solutions.name;
                this.home5.img = industry_solutions.more_thumbnail;
                let home5List = industry_solutions.val,arr = 1;
				if (home5List.length % 6 != 0) {
					arr = parseInt(home5List.length / 6) + 1;
				}else{
					arr = parseInt(home5List.length / 6)
				}
				for (let i = 0; i < arr; i++) {
					this.$set(this.home5.list, i, home5List.slice(i * 6, (i + 1) * 6));
                }
                this.home5.arr = arr;
                if(arr>1){
                    this.$nextTick(()=>{
                        new Swiper('#home5Right_swiper',{
                            prevButton:'.home5Right_prev',
                            nextButton:'.home5Right_next',
                        })
                    })
                }
                //城市合作案例
                let cooperation_case = this.$initNes(data.cooperation_case||{});
                this.home6.name = cooperation_case.name;
                this.home6.list = cooperation_case.val.map(item=>{
                    item.img_s = this.prefixImg+''+(item.img[0]&&item.img[0].url)
                    return item;
                })
                this.home6_select = 0;
                //助力城市产业
                let industrial_upgrading = this.$initNes(data.industrial_upgrading||{});
                this.$set(this, 'home7',{
                    name: industrial_upgrading.name,
                    content: industrial_upgrading.val[0]&&industrial_upgrading.val[0].content,
                })

                this.setScrol()
            })
        },
        initIntervalFun1(){
            const self_ = this;
            this.home2Right_swiper&&this.home2Right_swiper.destroy(true,true);
            this.$nextTick(()=>{
                this.home2Right_swiper = new Swiper ('#home2Right_swiper_'+this.selectArea, {
                    loop: true,
                    observer:true,
                    observeParents:true,
                    pagination: '.home2Right-pagination_'+this.selectArea,
                    paginationClickable :true,
                    autoplay: 4000,
                    onReachEnd: function(swiper){
                        console.log('----------------'+self_.selectArea)
                        if(self_.selectArea >= self_.home1_right.length-1){
                            self_.selectArea = 0;
                        }else{
                            self_.selectArea++
                        }
                        self_.initIntervalFun1()
                    }
                })
            })
            // setIntervalFun1 = setInterval(()=>{
            //     this.selectArea ++;
            //     if(this.selectArea >= this.home1_right.length){
            //         this.selectArea = 0;
            //     }
            //     this.home2Right_swiper&&this.home2Right_swiper.destroy(true,true);
            //     this.$nextTick(()=>{
            //         this.home2Right_swiper = new Swiper ('#home2Right_swiper_'+this.selectArea, {
            //             loop: true,
            //             pagination: '.home2Right-pagination_'+this.selectArea,
            //             paginationClickable :true,
            //         })
            //     })
            // },6000)
        },
        onSelectArea(i){
            // clearInterval(setIntervalFun1);
            this.selectArea = i;
            this.initIntervalFun1()
        },
        initIntervalFun2(){
            setIntervalFun2 = setInterval(() => {
                let i = this.seleHome3.split('_'),
                pIndex = Number(i[0]), index = Number(i[1]);
                
                if(pIndex >= this.home3_right.length-1 && index >= this.home3_right[pIndex].child.length-1){
                    pIndex = 0;
                    index = 0;
                } else if(index >= this.home3_right[pIndex].child.length-1){
                    pIndex = pIndex+1; 
                    index = 0;
                }else{
                    index = index+1; 
                }
                this.seleHome3 = `${pIndex}_${index}`
            }, 3000);
        },
        mouseleaveHome3(){
            this.initIntervalFun2()
        },
        mouseenterHome3(pIndex, i){
            clearInterval(setIntervalFun2)
            this.seleHome3 = `${pIndex}_${i}`
        }
    }
}
</script>
<style lang="scss" scoped>
ul li{
    list-style-type: none;
}
.home{
    position: relative;
    padding-top: 70px;
    .top_box{
        width: 100%;
        height: 630px;
        // padding: 0 5.5%;
        position: relative;
        .bg_img{
            width: 100%;
            height: 100%;
            @include backImg('../assets/img/home_6_1.png');
        }
        .video_box{
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
        .content_{
            position: absolute;
            top: 135px;
            right: 5%;
            float: right;
            z-index: 9;
            &>div{
                color: #ffffff;
                font-size: 36px;
                line-height: 80px;
                .big{
                    font-size: 60px;
                    font-weight: bold;
                }
            }
        }
        .home1Right-pagination{
            position: absolute;
            bottom: 40px;
            padding:0 5%;
            text-align: right;
            z-index: 9;
        }
    }
    .product_center{
        width: 100%;
        height: 730px;
        @include backImg('../assets/img/home_2.png');
        padding-top: 60px;
        position: relative;
        .home_box_title{
            position: absolute;
            bottom: 20px;
            left: 0;right: 0;
            width: 1200px;
            margin: 0 auto;
        }
        .content{
            width: 1200px;
            margin: 0 auto;
        }
        .left_{
            float: left;
            width: calc(100% - 860px);
            &>img{
                width: 210px;
                height: 90px;
                display: block;
                margin-left: 20px;
            }
            &>button{
                margin-top: 12px;
                margin-left: 20px;
                width: 122px;
                height: 36px;
                line-height: 36px;
                border: 1px solid $lv;
                color: $lv;
                border-radius: 18px;
                position: relative;
                padding-left: 20px;
                text-align: left;
                &>img{
                    position: absolute;
                    top: 4px;
                    width: 25px;
                    line-height: 36px;
                }
                &.hidden{
                    opacity: 0;
                    cursor: text;
                }
            }
            &>ul{
                margin-top: 20px;
                width: 324px;
                height: 390px;
                overflow-y: auto;
                li{
                    cursor: pointer;
                    min-height: 88px;
                    padding: 10px 14px;
                    &.active{
                        background-color: #ffffff;
                        box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.12);
                        .name{
                            color: $lv;
                            font-weight: bold;
                        }
                    }
                    .name{
                        font-size: 14px;
                        color: #333333;
                        line-height: 21px;
                        @include textOver(2)
                    }
                    .info{
                        display: inline-block;
                        width: 100%;
                        margin-top: 5px;
                        font-size: 12px;
                        line-height: 21px;
                        color: #999999;
                    }
                }
            }
        }
        .right_{
            float: right;
            width: 850px;
            box-shadow: 0 5px 30px 1px rgba(0, 126, 82, 0.25);
            .img_box{
                position: relative;
                height: 432px;
                .bg_img{
                    width: 100%;
                    height: 432px;
                    @include backImg('../assets/img/home_6_1.png');
                }
            }
            .home2Right-pagination{
                z-index: 9;
                position: absolute;
                padding: 0 20px;
                bottom: 15px;
                text-align: right;
            }
            .btn-list{
                width: 100%;
                &>button{
                    text-align: center;
                    height: 88px;
                    color: #333333;
                    line-height: 88px;
                    font-size: 14px;
                    background-color: #ffffff;
                    position: relative;
                    &:hover{
                        color: $lv;
                        font-size: 16px;
                    }
                    &.active{
                        background-color: $lv;
                        color: #ffffff;
                        &::after{
                            content: '';
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            width: 48px;
                            height: 6px;
                            margin: 0 auto;
                            background-color: #ffffff;
                        }
                    }
                }
            }
        }
    }
    .home_3_box{
       width: 100%; 
       min-height: 730px;
       position: relative;
       z-index: 0;
       .big_bg{
           position: absolute;
           left: 0;
           right: 0;
            width: 100%;
            height: 100%;
           @include backImg('../assets/img/test/home_3.png');
           z-index: -1;
       }
       .content{
            padding : 60px 0 40px;
            width: 1200px;
            margin: 0 auto;
       }
       .left_{
           display: inline-block;
           vertical-align: middle;
           width: 204px;
           padding-left: 15px;
           ul{
               li{
                   padding: 35px 0;
                   .title{
                       position: relative;
                       padding-left: 42px;
                       font-size: 24px;
                       color: #ffffff;
                       line-height: 32px;
                       margin-bottom: 14px;
                       &>img{
                           position: absolute;
                           left: 0;
                           top: 0;
                           width: 32px;
                           height: 32px;
                       }
                   }
                   .info{
                       cursor: pointer;
                       position: relative;
                       font-size: 14px;
                       line-height: 21px;
                        padding-left: 42px;
                        color: #fff;
                        opacity: 0.6;
                        &>img{
                           position: absolute;
                           left: 20px;
                           top: 0;
                           width: 20px;
                           height: 20px;
                        }
                        &:not(:last-child){
                            margin-bottom: 6px;
                        }
                        &:hover{
                            // text-decoration: underline;
                            opacity: 1;
                        }
                        &.active{
                            opacity: 1;
                        }
                   }
                   &:not(:last-child){
                       border-bottom: 1px solid rgba($color: #fff, $alpha: 0.4);
                   }
               }
           }
       }
       .middle_{
           width: calc(100% - 918px - 220px);
           display: inline-block;
       }
       .right_{
           display: inline-block;
           vertical-align: middle;
           width: 918px;
           height: 589px;
           .img_box{
               width: 100%;
               height: 100%;
               @include backImg('../assets/img/home_6_1.png');
           }
       }
    }
   
    .home_5_box{
        width: 100%;
        height: 735px;
        position: relative;
        z-index: 0;
        background-color: #0AC56E;
        display: inline-block;
        .left_{
            @include backImg('../assets/img/test/home_5.png');
            width: 38.5%;
            float: left;
            height: 100%;
            padding-top: 1px;
            padding-right: 30px;
            img{
                margin-top: 80px;
                width: 355px;
                height: 326px;
            }
            .home_box_title{
                margin-top: 65px;
                .english{
                    margin-top: 20px;
                    line-height: 64px;
                }
            }
        }
        .right_{
            position: relative;
            background-image: url('../assets/img/home_5.png');
            width: 60%;
            background-position: left center;
            background-repeat: no-repeat;
            float: right;
            height: 100%;
            padding: 1px;
             &::before,&::after{
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                top: -110px;
                height: 256px;
                background-image: url('../assets/img/home_5.png');
                z-index: -1;
            }
            &::after{
                top: initial;
                bottom:-110px;
            }

            .box-tab{
                margin: 130px 0 0 20px;
                .tab_{
                    color: #fff;
                    border: 1px solid rgba($color: #fff, $alpha: 0.4);
                    width: 240px;
                    height: 164px;
                    padding: 36px 16px;
                    float: left;
                    margin-right: 24px;
                    margin-bottom: 40px;
                    transition: all .2s ease;
                    cursor: pointer;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                    &:nth-child(3n+1){
                        clear: both;
                    }
                    &:hover{
                        background-color: #fff;
                        color: $lv;
                        transform: translateY(-5px)
                    }
                    .name{
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 21px;
                    }
                    .info{
                        font-size: 14px;
                        line-height: 21px;
                        margin-top: 15px;
                        @include textOver(2)
                    }    
                }
            }
            .next_prev_box{
                margin-left: 675px;
                &>div{
                    display: inline-block;
                    width: 55px;
                    height: 55px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    &.home5Right_prev{
                        background-image: url('../assets/img/no_j.png');
                        @include rotate(0);   
                        cursor: pointer;
                        &.swiper-button-disabled{
                            cursor: not-allowed;
                        }
                        &:not(.swiper-button-disabled):hover{
                           background-image: url('../assets/img/has_j.png');   
                           @include rotate();  
                        }
                    }
                    &.home5Right_next{
                        margin-left: 20px;
                        background-image: url('../assets/img/no_j.png'); 
                        @include rotate();
                        cursor: pointer;
                        &.swiper-button-disabled{
                            cursor: not-allowed;
                        }
                        &:not(.swiper-button-disabled):hover{
                           background-image: url('../assets/img/has_j.png');   
                           @include rotate(0);  
                        }
                    }
                }
            }
        }
            
        @media screen and (max-width: 1430px){
            .left_{
                width: 36%;
            }
            .right_{
                width: 63%;
                .box-tab{
                    margin-left: 10px;
                    .tab_{
                        width: 230px;
                    }
                }
                .next_prev_box{
                    margin-left: 617px;
                }
            }
        }
    }
    .home_6_box{
        padding: 80px 0 230px;
        .content_{
            width: 1200px;
            margin: 0 auto;
        }
        .city-list{
            margin-top: 35px;
            display: inline-block;
            width: 100%;
            &>div{
               margin-right: 75px;
               margin-bottom: 24px;
               width: 240px;
               height: 100px; 
               line-height: 100px;
               text-align: center;
               float: left;
                background: #FFFFFF;
                border: 1px solid #CCCCCC;
                position: relative;
                z-index: 0;
                font-size: 18px;
                color: #999999;
                transition: all .2s ease;  
                cursor: pointer;
                &:nth-child(4n){
                    margin-right: 0;
                }
                .bg_{
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 1;
                    @include backImg('../assets/img/home_6_1.png');
                    background-color: #cccccc;
                }
                &:hover{
                    color: $lv;
                    border-color: $lv;
                    //  transform: translateY(-5px) scale(1.1);
                }
                &.active{
                    color: #fff;
                    border-color: $lv;
                    box-shadow: 0px 8px 12px 0px rgba(0, 125, 81, 0.12);
                    .bg_{
                        opacity: 1;
                    }
                }
            }
        }
    }
    .home_7_box{
        position: absolute;
        bottom: 439px;
        left: 0;
        right: 0;
        z-index: 1;
        margin: 0 auto;
        width: 1200px;
        min-height: 400px;
        padding: 55px 55px;
        color: #FFFFFF;
        background: linear-gradient(180deg, #0AC56E, #2D3BCC);
        &.active{
            animation: slideInLeft 0.5s linear forwards;
        }
        .name{
            font-size: 24px;
            line-height: 21px;
        }
        .info{
            margin-top: 35px;
            font-size: 14px;
            line-height: 21px;
            height: 230px;
            overflow-y: auto;
        }
    }
    .home-footer{
        width: 100%;
        height: 636px;
        @include backImg('../assets/img/footer_1.png');
        padding-top: 1px;
        .info_img{
            width: 1010px;
            height: 46px;
            margin: 275px auto 0;
            display: block;
        }
    }
    .video-dialog{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 200;
        background-color: rgba(0,0,0,.7);
        >div{
            width: 962px;
            height: 541px;
            background: #000;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            >video{
                width: 100%;
                height: 100%;
            }
            .close_icon{
                position: absolute;
                width: 59px;
                height: 59px;
                line-height: 45px;
                top: -44px;
                right: -68px;
                cursor: pointer;
                background: rgba(0,0,0,.6);
                overflow: hidden;
                border-radius: 100px;
                color: #fff;
                text-align: center;
                font-size: 50px;
            }
        }
    }
}
</style>
<style lang="scss">
.home2Right-pagination{
    &>span{
        width: 24px;
        height: 4px;
        border-radius: 8px;
        background-color: #fff;
        opacity: 0.4;
        &.swiper-pagination-bullet-active{
            background-color: $lv;
            opacity: 1;
        }
    }
}
.home1Right-pagination{
    &>span{
        background-color: #000;
        height: 6px;
        width: 13px;
        border-radius: unset;
        opacity: 0.5;
    }
    .swiper-pagination-bullet-active{
        background-color:#ffff;
        opacity: 1;
    }
}
</style>