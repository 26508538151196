<template>
    <div class="expert-think-tank">
        <div class="content_">
            <div class="home_box_title">
                <span>{{info.name||'专家智库'}}</span>
                <div class="english absol_">EXPERT THINK TANK</div>
            </div>
            
            <div class="desc_" v-html="info.desc"></div>
            <div class="tap_title">数字经济创新研究院</div>
            <div class="tap_list">
                <div class="svg-box" v-for="item in info.list1" :key="item.id" 
                    @click="$router.push({name:'infomationDetail', query:{id: item.id, type:'expert_think'}})">
                    <img :src="item.thumbnail">
                    <span class="ellipsis">{{item.title}}</span>
                    <svg class="svg-border"><path d="M0 0 L280 0 L280 211 L0 211 L0 0"></path></svg>
                </div>
            </div>
            <div class="bottom_box">
                <div class="svg-box" v-for="item in info.list2" :key="item.id" 
                    >
                    <!-- @click="$router.push({name:'infomationDetail', query:{id: item.id, type:'expert_think'}})" -->
                    <div class="img_box" :style="{backgroundImage:'url('+item.thumbnail+')'}"></div>
                    <div class="name">{{item.title}}</div>
                    <div class="info">{{item.excerpt}}</div>
                    <svg class="svg-border"><path d="M0 0 L280 0 L280 320 L0 320 L0 0"></path></svg>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    data(){
        return{
        }
    },
    props:{
        info:{
            type:Object,
            default:()=>{
                return {
                    desc:'',
                    name:'',
                    list1:[],
                    list2:[],
                };
            }
        }
    },
}
</script>
<style lang="scss" scoped>
    .expert-think-tank{
        padding: 80px 0;
        .content_{
            width: 1200px;
            margin: 0 auto;
        }
        .tap_title{
            margin-top: 35px;
           height: 32px;
           line-height: 32px; 
           padding-left: 18px;
           font-size: 24px;
           color: #333333;
           position: relative;
           border-left: 6px solid $lv;
        }
        .tap_list{
            margin-top: 20px;
            color: #333333;
            display: inline-block;
            width: 100%;
            &>div{
                width: 280px;
                height: 211px;
                text-align: center;
                float: left;
                padding-top: 27px;
                transition: all .2s ease;
                margin-right: 24px;
                cursor: pointer;
                &:nth-of-type(4n) {
                    margin-right: 0
                }
                &>span{
                    display: block;
                    font-size: 14px;
                    line-height: 21px;
                    margin-top: 5px;
                }
                &>img{
                    width: 162px;
                    height: 138px;
                    display: block;
                    margin: 0 auto;
                }
                &:hover{
                    box-shadow: 0 4px 6px 0 rgba(0, 144, 77, 0.2), 0 9px 24px 0 rgba(0, 144, 77, 0.2);
                    background-color: #fff;
                    transform: translateY(-10px);
                }
            }
        }
        .desc_{
            font-size: 14px;
            color: #333333;
            line-height: 21px;
            margin-top: 20px;
        }
        .bottom_box{
            margin-top: 30px;
            display: inline-block;
            width: 100%;
            &>div{
                width: 280px;
                height: 320px;
                float: left;
                padding: 40px 36px 32px;
                transition: all .2s ease;
                margin-right: 24px;
                background: #F5F5F5;
                // cursor: pointer;
                &:nth-of-type(4n) {
                    margin-right: 0
                }
                .svg-border path{
                    @include svgBorderStroke(280, 320)
                }
                &:hover{
                    box-shadow: 0 4px 6px 0 rgba(0, 144, 77, 0.2), 0 9px 24px 0 rgba(0, 144, 77, 0.2);
                    background-color: #fff;
                    transform: translateY(-10px);
                    .svg-border path{
                      @include svgBorderStrokeHover(280, 320)
                    }
                }
                .img_box{
                    width: 126px;
                    height: 124px;
                    margin: 0 auto;
                    @include backImg('../../assets/img/home_6_1.png');
                    background-color: #ccc;
                }
                .name{
                    margin-top: 16px;
                    font-size: 18px;
                    line-height: 21px;
                    color: #333333;
                    text-align: center;
                }
                .info{
                   margin-top: 6px; 
                   font-size: 14px;
                    line-height: 21px;
                    color: #999999;
                    text-align: center;
                    @include textOver(4)
                }
            }
        }
    }
</style>